html {
    width: 100vw;
    margin: 0;

}
body {
    margin: 0;
    width: 100vw;
}

@font-face {
    font-family: 'EdwardianScriptITC';
    src: local('EdwardianScriptITC'), url(./fonts/EdwardianScriptITC.ttf) format('truetype');
}